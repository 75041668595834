<template>
    <UserForm :user="user" v-if="loaded" :readOnly="false" />
</template>

<script>
import {BModal, BButton, VBModal, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Auth} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import UserForm from './UserForm.vue'

export default {
  components: { 
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    UserForm
  },
  data() {
    return {
      user:null,
      loaded:false,
    }
  },
  methods:{
    
  },
  async mounted()
  {
      
    this.user=await Auth.get_user_with_id(this.$route.params.id)
    this.loaded=true
  }
}
</script>
